var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "button",
      class: _vm.classes,
      attrs: { type: "button", disabled: _vm.disabled },
      on: { click: _vm.onClick },
    },
    [
      _vm.iconLeft
        ? _c("icon", {
            staticClass: "iconLeft",
            class: { "mr-4": _vm.$slots.default },
            attrs: { name: _vm.iconLeft, color: _vm.iconColor, size: "20" },
          })
        : _vm._e(),
      _vm._t("default"),
      _vm.iconRight
        ? _c("icon", {
            staticClass: "iconRight",
            class: { "ml-4": _vm.$slots.default },
            attrs: { name: _vm.iconRight, color: _vm.iconColor, size: "20" },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }