var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        viewBox: "0 0 22 22",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M4 9V3C4 1.4 2.66667 1 2 1M10 16H17M10 6H14C14.5523 6 15 5.55228 15 5V3C15 2.44772 14.5523 2 14 2H10C9.44772 2 9 2.44772 9 3V5C9 5.55228 9.44772 6 10 6ZM7 15C7 16.6569 5.65685 18 4 18C2.34315 18 1 16.6569 1 15C1 13.3431 2.34315 12 4 12C5.65685 12 7 13.3431 7 15ZM11 13H16C16.5523 13 17 12.5523 17 12V10C17 9.44772 16.5523 9 16 9H11C10.4477 9 10 9.44772 10 10V12C10 12.5523 10.4477 13 11 13Z",
          stroke: "currentColor",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }