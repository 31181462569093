var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    { attrs: { viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" } },
    [
      _c("path", {
        attrs: {
          d: "M10 19L3 12M3 12L10 5M3 12L21 12",
          stroke: "currentColor",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }