var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(_vm.name, {
    tag: "component",
    attrs: { width: _vm.size, heigth: _vm.size, color: _vm.color },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }